import {
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
  Divider,
  Button,
} from '@mui/material'
import React from 'react'

import ChartsLegal from '@/components/Charts/Legal'

import { useGetProductMetricsQuery } from '@/services/organization/product/metric'
import {
  useCurrentOrganization,
  useCurrentProduct,
} from '@/hooks/useSession'

import { Color } from '@/components/ResultsProgressBar/types'

import DescriptiveProgressBar from '@/components/DescriptiveProgressBar'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

import {
  EXCELLENT,
  HUMANIZED_STATE,
  MEETING_REQUIREMENTS,
} from './types'

function DashboardLegallySpeaking(): React.ReactElement {
  const { id: organizationId } = useCurrentOrganization()
  const product = useCurrentProduct()
  const { id: productId } = product || { id: '' }

  const { data: metrics } = useGetProductMetricsQuery({
    params: {
      id: organizationId,
      productId,
    },
    query: { metric: [ 'legallySpeaking' ] },
  }, { refetchOnMountOrArgChange: true })

  const stats = metrics?.data?.legallySpeaking || {
    excellent: 0,
    meetingRequirements: 0,
    risky: 0,
    applicableLawScores: [],
  }

  const requiresDpiaCount = stats.applicableLawScores.reduce((prev: number, curr) => curr.requiresDpia ? prev + 1 : prev, 0)

  const getProgressColorFromState = (state: string) => {
    switch (state) {
      case EXCELLENT:
        return Color.GREEN
      case MEETING_REQUIREMENTS:
        return Color.BLUE
      default:
        return Color.ORANGE
    }
  }

  return (
    <DocumentWrapper title='Common Sense Privacy | Legally Speaking'>
      <ScreenWrapper bgcolor='grey.50'>
        <Container>
          <Grid container={true} spacing={4} justifyContent='center' alignItems='stretch' mb={4}>
            <Grid item={true} xs={12} sm={6} md={8}>
              <Stack spacing={4}>
                <Typography variant='h1'>Legally Speaking</Typography>
                <Typography variant='h4'>How are you doing legally?</Typography>
                <Typography variant='intro'>Laws that apply to your product set rules of conduct for how you can legally operate your business. These standards apply based on your target users, product type and service area. Review ratings provided below to determine where to focus your energy to improve your legal standing.
                </Typography>
                {/* using standalone styling for now while we come up with DPIA uses */}
                {!!requiresDpiaCount && (
                  <Grid sm={8} xs={12} md={4}>
                    <Button
                      variant='outlined'
                      disableRipple={true}
                      sx={{ cursor: 'default' }}
                    >DPIA Evaluation Required ({requiresDpiaCount})
                    </Button>
                  </Grid>

                )}
                <Stack direction='row' spacing={0.5} divider={<Divider sx={{ background: '#000' }} orientation='vertical' flexItem={true} />}>
                  <Typography><strong>Excellent:</strong> {stats.excellent}</Typography>
                  <Typography><strong>Meeting Requirements:</strong> {stats.meetingRequirements}</Typography>
                  <Typography><strong>Risky:</strong> {stats.risky}</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <Card variant='outlined'>
                <CardContent>
                  <Stack spacing={2} alignItems='center'>
                    <ChartsLegal stats={stats} />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Container>
          {stats.applicableLawScores.map(({
            lawId,
            abbrev,
            requiresDpia,
            scorePercentage,
            state,
          }) => (
            <DescriptiveProgressBar
              key={lawId}
              title={abbrev}
              requiresDpia={requiresDpia}
              description={(
                <Stack direction='row' spacing={2}>
                  <Typography>Your rating on {abbrev} is <strong>{HUMANIZED_STATE[state]}</strong>.</Typography>
                  {!!requiresDpia && <Typography color='green'>DPIA Evaluation Required</Typography>}
                </Stack>
              )}
              progress={scorePercentage}
              color={getProgressColorFromState(state)}
              linkPath={`/dashboard/legally-speaking/${lawId}`}
              linkText={getProgressColorFromState(state) === Color.GREEN ? 'See Details' : 'Improve Your Ratings'}
            />
          ))}
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(DashboardLegallySpeaking)
